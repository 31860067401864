/* generated by pull.js */
const manifest = {
  "noTranslations": true,
  "name": "Disable restore points",
  "description": "Disables all restore point creation. Be careful.",
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "tags": [
    "danger"
  ],
  "enabledByDefault": false
};
export default manifest;
